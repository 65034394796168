




import { Component, Prop, Vue } from 'vue-property-decorator';

import StatTile from '@/components/common/StatTile.vue';

import { numberFormat } from '@/util';
import {
  SurveyProperty,
  DetectionBbox,
  FaunaMedia,
  FaunaMediaTag,
} from '@/api';

import authModule from '@/store/Auth';

@Component({
  components: {
    StatTile,
  },
})
export default class PropertyStickybeakStats extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  get numberFormat() {
    return numberFormat;
  }

  async mounted() {
    const agreed = await DetectionBbox.where({
      tag_status: 'agreed',
    })
      .per(1)
      .first();

    const total = await DetectionBbox.per(1)
      .where({ tag_status__in: ['agreed', 'resolved', 'conflict'] })
      .first();

    const tags = await FaunaMediaTag.where({
      is_final: true,
    }).first();

    // const tags = await FaunaMediaTag.where({
    //   created_by: authModule.user.id,
    // }).first();

    // const imgs = await FaunaMedia.where({
    //   property__in: authModule.myProperties.map(p => p.id),
    // }).first();

    console.log('agreed', agreed.meta.pagination.count);
    console.log('total', total.meta.pagination.count);
    // console.log('imgs', imgs.meta.pagination.count);
    // console.log('tags', tags.meta.pagination.count);
  }
}
