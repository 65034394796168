








































import {
  Component,
  Vue,
  VModel,
  InjectReactive,
  Watch,
} from 'vue-property-decorator';

import { FaunaMediaTag, FaunaTag } from '@/api';

import authModule from '@/store/Auth';
import cacheModule from '@/store/Cache';

import Classifier from '@/components/classifier/Classifier.vue';
import UserAvatar from '@/components/common/UserAvatar.vue';
import BboxClassificationData from '@/components/classifier/BboxClassificationData.vue';

@Component({
  components: {
    UserAvatar,
    BboxClassificationData,
  },
})
export default class BboxDialog extends Vue {
  @VModel() show: boolean;

  @InjectReactive() classifier: Classifier;

  get isAdmin() {
    return authModule.isAdmin;
  }

  get orphanTags() {
    return this.classifier.orphanTags.map(fmt => ({
      fmt,
      tag: cacheModule.faunaTagsById(fmt.faunaTag.id as string),
    }));
  }

  get title() {
    return this.orphanTags.length
      ? 'Orphan Tags found'
      : 'No Orphan Tags found!';
  }

  get titleIcon() {
    return 'mdi-alert-box-outline';
  }

  deleteUserTag(tag: FaunaMediaTag) {
    this.$emit('delete-tag', tag);
    this.show = false;
  }
}
