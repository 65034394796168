







































































































































































import {
  Component,
  Prop,
  Vue,
  Watch,
  ProvideReactive,
} from 'vue-property-decorator';

import {
  SurveyProperty,
  FaunaTag,
  FaunaMedia,
  DiseaseStatus,
  FaunaMediaTag,
  FaunaSurveyStatus,
  FaunaSurvey,
  DetectionBbox,
} from '@/api';

import FaunaMediaThumb from '@/components/common/FaunaMediaThumb.vue';
import FilterButton from '@/components/common/FilterButton.vue';

import UserAvatar from '@/components/common/UserAvatar.vue';
import ItemBreadcrumb from '@/components/common/ItemBreadcrumb.vue';
import ClassifierSnack from '@/components/classifier/ClassifierSnack.vue';
import ClassifierActions from '@/components/classifier/ClassifierActions.vue';
import ClassifierNav from '@/components/classifier/ClassifierNav.vue';
import ClassifierImage from '@/components/classifier/ClassifierImage.vue';
import ClassifierImageInfo from '@/components/classifier/ClassifierImageInfo.vue';
import ClassifierDrawerBottom from '@/components/classifier/ClassifierDrawerBottom.vue';
import ChangeStatusDialog from '@/components/classifier/ChangeStatusDialog.vue';
import CommentDialog from '@/components/classifier/CommentDialog.vue';
import OrphanTagDialog from '@/components/classifier/OrphanTagDialog.vue';

import { NavigationGuardNext, RawLocation, Route } from 'vue-router';

import authModule from '@/store/Auth';
import cacheModule from '@/store/Cache';

import confirmDialog from '@/confirm-dialog';
import { debounce, isEqual } from 'lodash';
import { ConfidenceLevel } from '@/api/models/FaunaMediaTag';

import { numberFormat } from '@/util';

const debounceDelay = 300;

@Component({
  components: {
    UserAvatar,
    ItemBreadcrumb,
    FaunaMediaThumb,
    FilterButton,
    ClassifierSnack,
    ClassifierActions,
    ClassifierNav,
    ClassifierImage,
    ClassifierImageInfo,
    ClassifierDrawerBottom,
    ChangeStatusDialog,
    CommentDialog,
    OrphanTagDialog,
  },
})
export default class Classifier extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  get faunaSurveyId() {
    const id = this.$route.params.faunaSurveyId;
    if (Number.isNaN(parseInt(id, 10))) {
      return null;
    }
    return id;
  }

  get snack() {
    return this.$refs.snackbar as ClassifierSnack;
  }

  get numberFormat() {
    return numberFormat;
  }

  faunaSurvey: FaunaSurvey | null = null;

  bboxes: DetectionBbox[] = [];

  orphanTags: FaunaMediaTag[] = [];

  loading = false;

  // for nav
  // a pageful of fm
  faunaMedia: FaunaMedia[] = [];

  // for nav
  // total fm count
  total = 0;

  // for nav
  // how many items to show per page
  itemsPerPage = 100;

  // for nav
  // the total number of pages
  get pageCount() {
    return Math.ceil(this.total / this.itemsPerPage);
  }

  // for nav
  // the array index of the selected item
  selected = -1;

  // the active item
  // purposefully detached from a getter
  activeItem: FaunaMedia | null = null;

  // show/hide filters
  showFilters = false;

  get filterBtnIcon() {
    return this.showFilters ? 'mdi-filter-off' : 'mdi-filter';
  }

  get filterBtnTitle() {
    return this.showFilters ? 'Hide Filters' : 'Show Filters';
  }

  // for admins to remove orphan tags
  showOrphanTagDialog = false;

  // for admins to change the status
  showChangeStatusDialog = false;

  // set a comment
  showCommentDialog = false;

  // auto navigating to next bbox
  autoProgress = false;

  // auto dismiss the bbox dialog for agreed tags
  get autoDismissBboxDialog() {
    return this.autoProgress;
  }

  // recently selected tags
  recentTags: FaunaTag[] = [];

  @ProvideReactive() classifier: Classifier | null = null;

  get breadcrumbSurvey() {
    return this.activeItem ? this.activeItem.faunaSurvey : null;
  }

  get breadcrumbSite() {
    const siteId = this.breadcrumbSurvey
      ? this.breadcrumbSurvey.surveySite.id
      : null;
    if (!siteId) {
      return null;
    }
    return this.property.surveySites.find(site => site.id === siteId) || null;
  }

  // the selected item getter
  get selectedItem() {
    return this.faunaMedia[this.selected];
  }

  // current status of the survey
  get faunaSurveyStatus() {
    return this.faunaSurvey
      ? this.faunaSurvey.status
      : FaunaSurveyStatus.unknown;
  }

  get faunaSurveyPrettyStatus() {
    return this.faunaSurvey ? this.faunaSurvey.prettyStatus : 'Unknown';
  }

  get isDraft() {
    return this.faunaSurveyStatus === FaunaSurveyStatus.draft;
  }

  get isPublished() {
    return this.faunaSurveyStatus === FaunaSurveyStatus.published;
  }

  get isInProgress() {
    return this.faunaSurveyStatus === FaunaSurveyStatus.inProgress;
  }

  get isComplete() {
    return this.faunaSurveyStatus === FaunaSurveyStatus.assessed;
  }

  get classifierTitle() {
    if (!this.faunaSurveyId) {
      return `Viewing Photos for ${this.property.name}`;
    }
    if (this.isInProgress) {
      return `Survey #${this.faunaSurveyId} In Progress`;
    }
    if (this.isComplete) {
      return `Survey #${this.faunaSurveyId} Complete`;
    }
    if (this.isPublished) {
      return `Survey #${this.faunaSurveyId}`;
    }
    return 'Classifier';
  }

  get classifierSubtitle() {
    if (!this.faunaSurveyId) {
      return 'Read only';
    }
    if (this.isInProgress) {
      return `Classification in progress `;
    }
    if (this.isComplete) {
      return 'Classification Complete';
    }
    if (this.isPublished) {
      return 'Ready for Classification';
    }
    return 'Classifier';
  }

  // who am i?
  get isPropertyOwner() {
    return authModule.user && authModule.user.id === this.property.owner.id;
  }

  get isAdmin() {
    return authModule.isAdmin;
  }

  get isSurveyCreator() {
    return (
      this.faunaSurvey &&
      this.faunaSurvey.createdBy &&
      authModule.user &&
      authModule.user.id === this.faunaSurvey.createdBy.id
    );
  }

  get isSurveyAssessor() {
    return (
      this.faunaSurvey &&
      this.faunaSurvey.assessedBy &&
      authModule.user &&
      authModule.user.id === this.faunaSurvey.assessedBy.id
    );
  }

  get canPublish() {
    return (
      this.faunaSurvey &&
      this.faunaSurvey.status === FaunaSurveyStatus.draft &&
      this.total > 0 &&
      (this.isSurveyCreator || this.isPropertyOwner || this.isAdmin)
    );
  }

  // what can I do?
  get canEdit() {
    return (
      this.isAdmin ||
      (this.faunaSurveyStatus === FaunaSurveyStatus.inProgress &&
        (this.isSurveyAssessor || this.isPropertyOwner))
    );
  }

  get canStartTagging() {
    return (
      this.faunaSurvey &&
      this.faunaSurvey.status === FaunaSurveyStatus.published &&
      authModule.user &&
      authModule.canAssessSurveys(this.property)
    );
  }

  get canComplete() {
    return (
      this.faunaSurvey &&
      this.faunaSurvey.status === FaunaSurveyStatus.inProgress &&
      (this.isPropertyOwner || this.isSurveyAssessor || this.isAdmin)
    );
  }

  get propertyUsers() {
    const pu = cacheModule.propertyUsers(this.property.id as string) || [];
    console.log('pu', pu);
    return pu;
  }

  // used to find the page that an id is on
  get containsId() {
    return (this.$route.query.id as string) || undefined;
  }

  set containsId(id: string | undefined) {
    this.$router.replace({
      query: { ...this.$route.query, id },
    });
  }

  // the current page
  get page() {
    if (this.containsId) {
      return -1;
    }
    return parseInt((this.$route.query.page as string) || '1', 10);
  }

  set page(p: number) {
    this.$router.replace({
      query: { ...this.$route.query, id: undefined, page: p.toString() },
    });
  }

  get whereClause() {
    const clause: { [key: string]: unknown } = {};
    this.filterItems
      .filter(filterItem => !!filterItem.relationship)
      .forEach(filterItem => {
        clause[filterItem.relationship] =
          this.$route.query[filterItem.queryParam] || undefined;
      });

    return {
      fauna_survey: this.faunaSurveyId || undefined,
      survey_property: this.faunaSurveyId ? undefined : this.property.id,
      ...clause,
    };
  }

  get hasFilters() {
    return !!this.filterItems.filter(
      filterItem =>
        !!filterItem.relationship &&
        this.$route.query[filterItem.queryParam] !== undefined,
    ).length;
  }

  get surveySiteItems() {
    return this.property.surveySites.map(site => ({
      label: site.name,
      value: site.id,
    }));
  }

  get filterItems() {
    const siteFilter = {
      label: 'Site',
      relationship: 'surveySite',
      queryParam: 'survey-site',
      items: this.surveySiteItems,
      multiple: false,
    };

    const remainingFilters = [
      {
        label: 'Has Tags',
        relationship: 'has_tags',
        queryParam: 'has-tags',
        items: [
          {
            label: 'Yes',
            value: 'true',
          },
          {
            label: 'No',
            value: 'false',
          },
        ],
        multiple: false,
      },
      {
        label: 'Tagged by',
        relationship: 'tagged_by__in',
        queryParam: 'tagged-by',
        items: this.propertyUsers.map(pu => ({
          label: pu.name,
          value: pu.id,
        })),
        multiple: true,
      },
      {
        label: 'Has Favourites',
        relationship: 'has_favourites',
        queryParam: 'has-favourites',
        items: [
          {
            label: 'Yes',
            value: 'true',
          },
          {
            label: 'No',
            value: 'false',
          },
        ],
        multiple: false,
      },
      {
        label: 'Favourited by',
        relationship: 'favourited_by__in',
        queryParam: 'favourited-by',
        items: this.propertyUsers.map(pu => ({
          label: pu.name,
          value: pu.id,
        })),
        multiple: true,
      },
      {
        label: 'Has Comment',
        relationship: 'has_comment',
        queryParam: 'has-comment',
        items: [
          {
            label: 'Yes',
            value: 'true',
          },
          {
            label: 'No',
            value: 'false',
          },
        ],
        multiple: false,
      },
      {
        label: 'Includes',
        relationship: 'final_tags__in',
        queryParam: 'fauna-tag',
        items: cacheModule.faunaTagFilterItems,
        multiple: true,
      },
      {
        label: 'Excludes',
        relationship: 'final_tags__exclude_in',
        queryParam: 'fauna-tag-exclude',
        items: cacheModule.faunaTagFilterItems,
        multiple: true,
      },
      {
        label: 'AI Tag Includes',
        relationship: 'ai_tags__in',
        queryParam: 'ai-tag',
        items: cacheModule.faunaTagFilterItems,
        multiple: true,
      },
      {
        label: 'AI Tag Excludes',
        relationship: 'ai_tags__exclude_in',
        queryParam: 'ai-tag-exclude',
        items: cacheModule.faunaTagFilterItems,
        multiple: true,
      },
      {
        label: 'User Tag Includes',
        relationship: 'user_tags__in',
        queryParam: 'user-tag',
        items: cacheModule.faunaTagFilterItems,
        multiple: true,
      },
      {
        label: 'User Tag Excludes',
        relationship: 'user_tags__exclude_in',
        queryParam: 'user-tag-exclude',
        items: cacheModule.faunaTagFilterItems,
        multiple: true,
      },
      {
        label: 'Expert Tag Includes',
        relationship: 'expert_tags__in',
        queryParam: 'expert-tag',
        items: cacheModule.faunaTagFilterItems,
        multiple: true,
      },
      {
        label: 'Expert Tag Excludes',
        relationship: 'expert_tags__exclude_in',
        queryParam: 'expert-tag-exclude',
        items: cacheModule.faunaTagFilterItems,
        multiple: true,
      },
      {
        label: 'Blank Images',
        relationship: 'is_blank',
        queryParam: 'is-blank',
        items: [
          {
            label: 'Blanks',
            value: 'true',
          },
          {
            label: 'Non Blanks',
            value: 'false',
          },
        ],
        multiple: false,
      },
      {
        label: 'Orphan Tags',
        relationship: 'has_orphan_tags',
        queryParam: 'orphan-tags',
        items: [
          {
            label: 'True',
            value: 'true',
          },
          {
            label: 'False',
            value: 'false',
          },
        ],
        multiple: false,
      },
    ];

    return this.faunaSurveyId
      ? remainingFilters
      : [siteFilter, ...remainingFilters];
  }

  // debounced function to set the active item
  get setActiveItem() {
    return debounce(this.doSetActiveItem, debounceDelay);
  }

  // debounced function to update the fm
  get update() {
    return debounce(this.updateFm, debounceDelay);
  }

  // debounced function to update the fm
  get getFaunaSurvey() {
    return debounce(this.doGetFaunaSurvey, debounceDelay);
  }

  get mediaFactory() {
    // TODO: How to handle old data
    return () => FaunaMedia.includes(['faunaSurvey']);
  }

  doSetActiveItem() {
    this.activeItem = this.selectedItem;
  }

  async doGetFaunaSurvey() {
    if (this.faunaSurveyId) {
      this.faunaSurvey = (await FaunaSurvey.find(this.faunaSurveyId)).data;
    } else {
      this.faunaSurvey = null;
    }
  }

  imageLoaded() {
    if (this.autoProgress) {
      this.findNext();
    }
  }

  imageAction() {
    console.log('image action');
    if (this.autoProgress) {
      this.findNext();
    }
  }

  /**
   * refresh a given fauna media item
   * replace the contents in the fm array
   */
  async refreshFaunaMedia(faunaMediaId: string) {
    if (this.loading) {
      return;
    }
    try {
      this.loading = true;
      // this is a new ting
      this.getFaunaSurvey();

      // TODO: I could include here?
      await this.refreshBboxes(faunaMediaId);
      await this.refreshOrphanTags(faunaMediaId);
      const faunaMedia = (await this.mediaFactory().find(faunaMediaId)).data;
      const inx = this.faunaMedia.findIndex(item => item.id === faunaMediaId);
      if (inx !== -1) {
        this.faunaMedia.splice(inx, 1, faunaMedia);
      }
    } catch (e) {
      this.snack.setError({
        text: 'Could not refresh fm',
        errors: (e as ErrorResponse).response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  async refreshOrphanTags(faunaMediaId: string) {
    const orphanTags = (
      await FaunaMediaTag.where({
        fauna_media: faunaMediaId,
        is_orphan: true,
      }).all()
    ).data;

    if (this.activeItem && this.activeItem.id === faunaMediaId) {
      this.orphanTags = orphanTags;
    } else {
      console.warn('got the wrong orphan tags, emptying');
      this.orphanTags = [];
    }
  }

  async refreshBboxes(faunaMediaId: string) {
    const bboxes = (
      await DetectionBbox.includes(['faunaMediaTags'])
        .where({
          faunaMedia: faunaMediaId,
          category: 'animal',
          isBlank: false,
        })
        .all()
    ).data;

    // sort the bboxes by index
    // this is to try and prevent zindex issues with overlapping bboxes
    if (this.activeItem && this.activeItem.id === faunaMediaId) {
      this.bboxes = bboxes.sort((a, b) => b.index - a.index);
    } else {
      console.warn('got the wrong bboxes, doing nothing');
    }
  }

  /**
   * fetches the list of available fauna media
   * pagination, filtering etc
   */
  async updateFm() {
    try {
      this.loading = true;
      const result = await this.mediaFactory()
        .where(this.whereClause)
        .extraParams({
          page: {
            contains_id: this.containsId ? this.containsId : undefined,
            number: this.containsId ? undefined : this.page,
            size: this.itemsPerPage,
          },
        })
        .per(this.itemsPerPage)
        .order({ timestamp: 'asc' })
        .all();

      this.total = result.meta.pagination.count;
      this.faunaMedia = result.data;

      // select the fauna media with the correct id
      let index = -1;
      if (this.containsId) {
        index = this.faunaMedia.findIndex(fm => fm.id === this.containsId);
      }
      this.selected = index === -1 ? 0 : index;

      // set the correct page according to returned query
      if (this.page !== result.meta.pagination.page) {
        this.page = result.meta.pagination.page;
      }
    } catch (e) {
      this.snack.setError({
        text: 'Could not load',
        errors: (e as ErrorResponse).response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  prevImage() {
    this.autoProgress = false;
    // check all images before selected
    const index = this.faunaMedia
      .slice(0, this.selected)
      .findLastIndex(fm =>
        this.isAdmin
          ? fm.tagStatusNone > 0 || fm.tagStatusConflict > 0
          : fm.tagStatusNone > 0,
      );
    if (index !== -1) {
      this.selected = index;
    }
  }

  nextImage() {
    this.autoProgress = false;
    // check all images after selected
    const index = this.faunaMedia
      .slice(this.selected + 1)
      .findIndex(fm =>
        this.isAdmin
          ? fm.tagStatusNone > 0 || fm.tagStatusConflict > 0
          : fm.tagStatusNone > 0,
      );
    if (index !== -1) {
      this.selected = index + this.selected + 1;
    }
  }

  /**
   * get the next bbox with a tag status none
   * progress to the next non-resolved image if there arent any
   * progress to the next page if there are no images?
   */
  findNext() {
    // admins care about conflicts
    const options = this.isAdmin ? ['none', 'conflict'] : ['none'];

    const bbox = this.bboxes.find(b => options.includes(b.tagStatus));
    if (bbox) {
      (this.$refs.img as ClassifierImage).bboxClickHandler(bbox);
      return;
    }

    // first check the proceeding images
    let index = this.faunaMedia
      .slice(this.selected)
      .findIndex(fm =>
        this.isAdmin
          ? fm.tagStatusNone > 0 || fm.tagStatusConflict > 0
          : fm.tagStatusNone > 0,
      );
    if (index !== -1) {
      this.selected = index + this.selected;
      return;
    }

    // next check images from the start
    index = this.faunaMedia.findIndex(fm =>
      this.isAdmin
        ? fm.tagStatusNone > 0 || fm.tagStatusConflict > 0
        : fm.tagStatusNone > 0,
    );
    if (index !== -1) {
      this.selected = index;
      return;
    }

    this.askForNextPage();
  }

  async askForNextPage() {
    if (this.page === this.pageCount) {
      return;
    }
    const selection = await confirmDialog({
      title: 'Nice work!',
      description:
        "You've tagged all the photos on this page. Would you like to jump to the next page?",
      buttons: [
        {
          key: 'cancel',
          title: 'Cancel',
          color: 'grey',
          text: true,
        },
        {
          key: 'confirm',
          title: 'Next',
          color: 'primary',
          outlined: false,
        },
      ],
    });
    if (selection !== 'confirm') {
      return;
    }
    this.page += 1;
  }

  async confidenceLevelDialog() {
    return confirmDialog({
      title: 'Confidence Level',
      description: 'On a scale of 1 - 5, how confident are you?',
      block: true,
      buttons: [
        {
          key: ConfidenceLevel.very_low,
          title: '1',
          color: 'grey',
          outlined: true,
        },
        {
          key: ConfidenceLevel.low,
          title: '2',
          color: 'grey',
          outlined: true,
        },
        {
          key: ConfidenceLevel.medium,
          title: '3',
          color: 'grey',
          outlined: true,
        },
        {
          key: ConfidenceLevel.high,
          title: '4',
          color: 'grey',
          outlined: true,
        },
        {
          key: ConfidenceLevel.very_high,
          title: '5',
          color: 'grey',
          outlined: true,
        },
      ],
    });
  }

  // TODO: not yet implemented
  async diseaseStatusDialog() {
    return confirmDialog({
      title: 'Disease Status',
      description:
        'Please indicate whether or not you can identify disease present for this tag',
      block: true,
      buttons: [
        {
          key: DiseaseStatus.diseased,
          title: 'Diseased',
          color: 'red white--text',
          outlined: false,
        },
        {
          key: DiseaseStatus.healthy,
          title: 'Healthy',
          color: 'green white--text',
          outlined: false,
        },
        {
          key: DiseaseStatus.unsure,
          title: 'Unsure',
          color: 'grey',
          outlined: true,
        },
      ],
    });
  }

  async publish() {
    if (this.loading || !this.faunaSurvey || !authModule.user) {
      return;
    }
    const selection = await confirmDialog({
      title: 'Publish survey?',
      description:
        "Once published, the survey will become available for tagging. Please make sure you've uploaded all of your photos before continuing.",
      buttons: [
        {
          key: 'cancel',
          title: 'Cancel',
          color: 'grey',
          text: true,
        },
        {
          key: 'confirm',
          title: 'Publish',
          color: 'primary',
          outlined: false,
        },
      ],
    });
    if (selection !== 'confirm') {
      return;
    }
    try {
      this.loading = true;
      this.faunaSurvey.status = FaunaSurveyStatus.published;
      await this.faunaSurvey.save();
    } finally {
      this.loading = false;
    }
  }

  async startTagging() {
    if (this.loading || !this.faunaSurvey || !authModule.user) {
      return;
    }
    const selection = await confirmDialog({
      title: 'Start Tagging?',
      description:
        'You will be able to stop and return to tagging images from this survey at your own leisure. Just be sure not to mark the survey as complete until all images are tagged!',
      buttons: [
        {
          key: 'cancel',
          title: 'Cancel',
          color: 'grey',
          text: true,
        },
        {
          key: 'confirm',
          title: "Let's start",
          color: 'primary',
          outlined: false,
        },
      ],
    });
    if (selection !== 'confirm') {
      return;
    }
    try {
      this.loading = true;
      this.faunaSurvey.status = FaunaSurveyStatus.inProgress;
      this.faunaSurvey.assessedBy = authModule.user;
      await this.faunaSurvey.save({ with: 'assessedBy.id' });
      this.getFaunaSurvey();
    } finally {
      this.loading = false;
    }
  }

  // TODO: This feature is not yet implemented!
  /*
  async setConfidenceLevel(fmTag: FaunaMediaTag) {
    const confidenceLevel = await this.confidenceLevelDialog();
    if (confidenceLevel === null) {
      return;
    }

    fmTag.confidenceLevel = confidenceLevel as ConfidenceLevel;
    try {
      this.loading = true;
      await fmTag.save();
      this.refreshFaunaMedia(fmTag.faunaMedia.id as string);
      this.snack.setSuccess('Confidence level updated');
    } catch (e) {
      this.snack.setError({
        text: 'Could not set confidence level',
        errors: (e as ErrorResponse).response.errors,
      });
    } finally {
      this.loading = false;
    }
  }
  */

  async markAsComplete() {
    if (this.loading) {
      console.warn('already loading');
      return;
    }
    if (!this.faunaSurvey) {
      console.warn('no fauna survey');
      return;
    }

    const selection = await confirmDialog({
      title: 'All done?',
      description:
        this.faunaSurvey.tagStatusNone > 0
          ? `Are you sure? There are still <strong>${this.faunaSurvey.tagStatusNone}</strong> detections that have not been tagged. By clicking confirm you will no longer be able to add or edit any tags in this survey.`
          : 'Are you sure? By clicking confirm you will no longer be able to add or edit any tags in this survey.',
      buttons: [
        {
          key: 'cancel',
          title: 'Cancel',
          color: 'grey',
          text: true,
        },
        {
          key: 'confirm',
          title: "I'm all done",
          color: 'primary',
          outlined: false,
        },
      ],
    });
    if (selection !== 'confirm') {
      return;
    }
    try {
      this.loading = true;
      this.faunaSurvey.status = FaunaSurveyStatus.assessed;
      await this.faunaSurvey.save();
      this.getFaunaSurvey();
    } finally {
      this.loading = false;
    }
  }

  async mounted() {
    cacheModule.getPropertyUsers(this.property.id as string);
    await this.getFaunaSurvey();
    await this.update();
    this.showFilters = this.hasFilters;
  }

  created() {
    // TODO: do something with this!
    this.classifier = this;
  }

  beforeDestroy() {
    this.classifier = null;
  }

  @Watch('page')
  pageChanged(newVal: number, oldVal: number) {
    if (oldVal !== -1) {
      this.update();
    }
  }

  @Watch('whereClause')
  whereChanged(
    newVal: { [key: string]: unknown },
    oldVal: { [key: string]: unknown },
  ) {
    if (!isEqual(newVal, oldVal)) {
      this.update();
    }
  }

  @Watch('faunaSurveyId')
  surveyIdChanged() {
    this.getFaunaSurvey();
  }

  // watch the index change
  // not the item change
  @Watch('selected')
  selectedChanged() {
    this.bboxes = [];
  }

  @Watch('selectedItem')
  selectedItemChanged() {
    this.setActiveItem();
  }

  @Watch('showChangeStatusDialog')
  showChangeStatusDialogChanged() {
    if (!this.showChangeStatusDialog) {
      this.getFaunaSurvey();
    }
  }

  @Watch('showCommentDialog')
  showCommentDialogChanged() {
    if (!this.showCommentDialog && this.activeItem) {
      this.refreshFaunaMedia(this.activeItem.id as string);
    }
  }

  async beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext) {
    if (to.query['is-blank'] === undefined) {
      next({
        ...to,
        query: { ...to.query, 'is-blank': 'false' },
      } as RawLocation);
    } else {
      next();
    }
  }
}
