


















import { Component, Vue } from 'vue-property-decorator';
import { UsageSummary } from '@/api';

import StatTile from '@/components/common/StatTile.vue';

import dayjs from 'dayjs';

@Component({ components: { StatTile } })
export default class UsageSummaryDashboard extends Vue {
  usageSummary: UsageSummary | null = null;

  async getUsageSummary() {
    this.usageSummary = (
      await UsageSummary.where({
        start_date: dayjs()
          .subtract(1, 'month')
          .format('YYYY-MM-DD'),
        end_date: dayjs().format('YYYY-MM-DD'),
      }).first()
    ).data;
    console.log(this.usageSummary);
  }

  mounted() {
    this.getUsageSummary();
  }
}
