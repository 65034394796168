






































import { SurveyProperty, FaunaMedia } from '@/api';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import EmptyItem from '@/components/common/EmptyItem.vue';
import FilterButton from '@/components/common/FilterButton.vue';
import FaunaMediaThumb from '@/components/common/FaunaMediaThumb.vue';
import Classifier from '@/components/classifier/Classifier.vue';
import CompactPagination from '@/components/upload/CompactPagination.vue';

import cacheModule from '@/store/Cache';
import snackModule from '@/store/Snack';

import { debounce } from 'lodash';
import { useSpraypaintCache } from '@/cacheUtil';

@Component({
  components: {
    CompactPagination,
    EmptyItem,
    FaunaMediaThumb,
    FilterButton,
    Classifier,
  },
})
export default class PropertyMedia extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  @Prop({ default: false }) readonly favOnly: boolean;

  faunaMedia: FaunaMedia[] = [];

  loading = false;

  firstLoad = true;

  total = 0;

  itemsPerPage = 100;

  get page() {
    return parseInt((this.$route.query.page as string) || '1', 10);
  }

  set page(p: number) {
    this.$router.replace({
      query: { ...this.$route.query, page: p.toString() },
    });
  }

  get pageCount() {
    return Math.ceil(this.total / this.itemsPerPage);
  }

  get displayCount() {
    return `Showing ${1 + (this.page - 1) * this.itemsPerPage} - ${Math.min(
      this.page * this.itemsPerPage,
      this.total,
    )} of ${this.total}`;
  }

  get update() {
    return debounce(this.doUpdate, 300);
  }

  get surveySiteItems() {
    return this.property.surveySites.map(site => ({
      label: site.name,
      value: site.id,
    }));
  }

  get tagById() {
    return cacheModule.faunaTagsById;
  }

  get propertyUsers() {
    return cacheModule.propertyUsers(this.property.id as string) || [];
  }

  get filterItems() {
    return [
      {
        label: 'Site',
        relationship: 'surveySite',
        queryParam: 'survey-site',
        items: this.surveySiteItems,
        multiple: false,
      },
      {
        label: 'Has Tags',
        relationship: 'has_tags',
        queryParam: 'has-tags',
        items: [
          {
            label: 'Yes',
            value: 'true',
          },
          {
            label: 'No',
            value: 'false',
          },
        ],
        multiple: false,
      },
      {
        label: 'Tagged by',
        relationship: 'tagged_by__in',
        queryParam: 'tagged-by',
        items: this.propertyUsers.map(user => ({
          label: user.name,
          value: user.id,
        })),
        multiple: true,
      },
      {
        label: 'Has Favourites',
        relationship: 'has_favourites',
        queryParam: 'has-favourites',
        items: [
          {
            label: 'Yes',
            value: 'true',
          },
          {
            label: 'No',
            value: 'false',
          },
        ],
        multiple: false,
      },
      {
        label: 'Favourited by',
        relationship: 'favourited_by__in',
        queryParam: 'favourited-by',
        items: this.propertyUsers.map(user => ({
          label: user.name,
          value: user.id,
        })),
        multiple: true,
      },
      {
        label: 'Has Comment',
        relationship: 'has_comment',
        queryParam: 'has-comment',
        items: [
          {
            label: 'Yes',
            value: 'true',
          },
          {
            label: 'No',
            value: 'false',
          },
        ],
        multiple: false,
      },
      {
        label: 'Includes',
        relationship: 'fauna_tag__in',
        queryParam: 'fauna-tag',
        items: cacheModule.faunaTagFilterItems,
        multiple: true,
      },
      {
        label: 'Excludes',
        relationship: 'fauna_tag__exclude_in',
        queryParam: 'fauna-tag-exclude',
        items: cacheModule.faunaTagFilterItems,
        multiple: true,
      },
      {
        label: 'No Animal',
        relationship: 'no_fauna',
        queryParam: 'no-animal',
        items: [
          {
            label: 'Yes',
            value: 'true',
          },
          {
            label: 'No',
            value: 'false',
          },
        ],
        multiple: false,
      },
      {
        label: 'Blank Images',
        relationship: 'is_blank',
        queryParam: 'is-blank',
        items: [
          {
            label: 'Blanks',
            value: 'true',
          },
          {
            label: 'Non Blanks',
            value: 'false',
          },
        ],
        multiple: false,
      },
      {
        label: 'Orphan Tags',
        relationship: 'has_orphan_tags',
        queryParam: 'orphan-tags',
        items: [
          {
            label: 'True',
            value: 'true',
          },
          {
            label: 'False',
            value: 'false',
          },
        ],
        multiple: false,
      },
    ];
  }

  get whereClause() {
    const clause: { [key: string]: unknown } = {};
    this.filterItems.forEach(filterItem => {
      clause[filterItem.relationship] =
        this.$route.query[filterItem.queryParam] || undefined;
    });
    return clause;
  }

  get query() {
    return {
      ...this.whereClause,
      sns_status__in: ['none', 'predicted'],
      survey_property: this.property.id,

      // has_tags: !this.favOnly ? true : undefined,
      // has_favourites: this.favOnly ? true : undefined,
    };
  }

  async doUpdate() {
    this.loading = true;
    try {
      const result = await useSpraypaintCache(
        FaunaMedia.includes(['faunaSurvey'])
          .where(this.query)
          .order({ timestamp: 'asc' })
          .page(this.page)
          .per(this.itemsPerPage),
      );
      this.faunaMedia = result.data;
      this.total = result.meta.pagination.count;
    } catch (e) {
      this.faunaMedia = [];
      snackModule.setError({
        text: 'Could not load',
        errors: (e as ErrorResponse).response.errors,
      });
    } finally {
      this.firstLoad = false;
      this.loading = false;
    }
  }

  mounted() {
    this.update();
    cacheModule.getProperty(this.property.id as string);
  }

  @Watch('page')
  @Watch('whereClause')
  optionsChanged() {
    this.update();
  }
}
