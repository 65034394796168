import { ApplicationRecord, SurveyProperty } from '@/api';
import { memoize } from 'lodash';
import { Scope } from 'spraypaint';
import TTLCache from '@isaacs/ttlcache';
import { CollectionProxy } from 'spraypaint/lib-esm/proxies';

// setup the cache constructor
class MemoizeCache<K, V> extends TTLCache<K, V> {
  constructor() {
    super({
      // max number of items
      max: 200,
      // expiry (ms)
      ttl: 60 * 1000,
    });
  }
}

// assign the cache constructor to the memoize function
// all instances of memoize will get a new cache
memoize.Cache = MemoizeCache;

// cache fn for single properties
export const usePropertySingle = memoize((id: string) =>
  SurveyProperty.includes(['surveySites']).find(id),
);

type CacheFn = <T extends ApplicationRecord>(
  scope: Scope<T>,
) => Promise<CollectionProxy<T>>;

// cache fn for lists
export const useSpraypaintCache: CacheFn = memoize(
  <T extends ApplicationRecord>(scope: Scope<T>) => scope.all(),
  <T extends ApplicationRecord>(scope: Scope<T>) => {
    return scope.toQueryParams();
  },
);
