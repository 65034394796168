

























































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { FaunaSurvey, SurveyProperty, FaunaSurveyStatus } from '@/api';

// TODO: handle no sites
import GetStartedSite from '@/components/common/GetStartedSite.vue';

import FaunaMediaGallery from '@/components/upload/FaunaMediaGallery.vue';
import FaunaMediaUploadDialog from '@/components/upload/FaunaMediaUploadDialog.vue';
import FaunaMediaUploader from '@/components/upload/FaunaMediaUploader.vue';
import AlertAccordion from '@/components/common/AlertAccordion.vue';

import StatBtn from '@/components/common/StatBtn.vue';
import DatePicker from '@/components/common/DatePicker.vue';

import { numberFormat } from '@/util';

@Component({
  components: {
    GetStartedSite,
    FaunaMediaGallery,
    FaunaMediaUploadDialog,
    FaunaMediaUploader,
    AlertAccordion,
    StatBtn,
    DatePicker,
  },
})
export default class SurveyEdit extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  @Prop({ required: true }) readonly survey: FaunaSurvey;

  @Prop({ required: true }) readonly updatedTs: string;

  get numberFormat() {
    return numberFormat;
  }

  get surveyInProgress() {
    return this.survey.status === FaunaSurveyStatus.inProgress;
  }
}
