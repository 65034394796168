










































































































import { Component, Vue, Watch } from 'vue-property-decorator';

import PageHeader from '@/components/common/PageHeader.vue';
import PropertySwap from '@/components/property/PropertySwap.vue';
import NotFound from '@/components/common/NotFound.vue';

import UserAvatar from '@/components/common/UserAvatar.vue';

import cacheModule from '@/store/Cache';
import authModule from '@/store/Auth';

import { numberFormat } from '@/util';

@Component({
  components: {
    UserAvatar,
    PageHeader,
    PropertySwap,
    NotFound,
  },
})
export default class PropertySingleView extends Vue {
  get propertyId() {
    return this.$route.params.propertyId;
  }

  get property() {
    return cacheModule.property(this.propertyId);
  }

  get sbCompatible() {
    return this.property && this.property.sbCompatible === 'compatible';
  }

  get isAdmin() {
    return authModule.isAdmin;
  }

  get propertySurveyCount() {
    return this.property ? this.property.surveyCount : 0;
  }

  get propertyFaunaCount() {
    return this.property ? this.property.propertyFaunaMediaCount : 0;
  }

  get propertyFavouriteCount() {
    // TODO: fix this
    return 0;
  }

  get numberFormat() {
    return numberFormat;
  }

  isNotFound = false;

  @Watch('propertyId', { immediate: true })
  async propertyIdChanged() {
    try {
      cacheModule.getProperty(this.propertyId);
      authModule.setDefaultPropertyId(this.propertyId);
    } catch (e) {
      this.isNotFound = true;
    }
  }
}
