import { Model, Attr, BelongsTo, HasOne } from 'spraypaint';
import { FaunaSurvey } from '@/api';

import ApplicationRecord from '../ApplicationRecord';

@Model()
export default class FaunaSurveyReport extends ApplicationRecord {
  static jsonapiType = 'fauna-survey-reports';

  static listHeaders() {
    return [
      {
        text: 'ID',
        value: 'id',
        width: 100,
      },
      {
        text: 'Created',
        value: 'createdAt',
      },
    ];
  }

  static scopeFactory() {
    return FaunaSurveyReport.includes([]);
  }

  @Attr() createdAt: string;

  @HasOne() faunaSurvey: FaunaSurvey;
}
