





































































































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator';

import ItemBreadcrumb from '@/components/common/ItemBreadcrumb.vue';
import PropertyOutline from '@/components/property/PropertyOutline.vue';
import TableMenuButton from '@/components/common/TableMenuButton.vue';
import UserAvatar from '@/components/common/UserAvatar.vue';
import ImageZipPreview from '@/components/common/ImageZipPreview.vue';
import StatBtn from '@/components/common/StatBtn.vue';
import FaunaSurveyChip from '@/components/common/FaunaSurveyChip.vue';

import { FaunaSurvey, FaunaSurveyStatus, FaunaMedia } from '@/api';

import authModule from '@/store/Auth';
import cacheModule from '@/store/Cache';
import snackModule from '@/store/Snack';
import userModule from '@/store/User';

import { throttle } from 'lodash';

import { numberFormat } from '@/util';
import { useSpraypaintCache } from '@/cacheUtil';

import surveyReportDialog from '@/survey-report-dialog';

@Component({
  components: {
    ItemBreadcrumb,
    UserAvatar,
    ImageZipPreview,
    StatBtn,
    PropertyOutline,
    TableMenuButton,
    FaunaSurveyChip,
  },
})
export default class SurveyListItem extends Vue {
  @Prop({ required: true }) readonly surveyStub: FaunaSurvey;

  loading = false;

  faunaMediaThumbnails: string[] = [];

  get property() {
    return userModule.propertyBySiteId(this.survey.surveySite.id as string);
  }

  get survey() {
    return cacheModule.survey(this.surveyStub.id as string) || this.surveyStub;
  }

  get sbCompatible() {
    return this.property && this.property.sbCompatible === 'compatible';
  }

  get site() {
    const site =
      this.property &&
      this.property.surveySites.find(s => s.id === this.survey.surveySite.id);

    return site;
  }

  get isProcessing() {
    return this.survey.snsStatusPredicted < this.survey.totalCount;
  }

  get isAdmin() {
    return authModule.isAdmin;
  }

  get isDraft() {
    return this.survey.status === FaunaSurveyStatus.draft;
  }

  get isPublished() {
    return this.survey.status === FaunaSurveyStatus.published;
  }

  get isInProgress() {
    return this.survey.status === FaunaSurveyStatus.inProgress;
  }

  get isComplete() {
    return this.survey.status === FaunaSurveyStatus.assessed;
  }

  get fmCount() {
    return this.survey.faunaMediaCount;
  }

  get surveyTitle() {
    return this.survey.formattedDate;
  }

  get editSurveyLink() {
    if (this.isDraft || (this.isPublished && this.isProcessing)) {
      return {
        name: 'property-surveys-edit',
        params: {
          propertyId: this.property ? this.property.id : undefined,
          surveyId: this.survey.id,
        },
      };
    }
    return {
      name: 'classifier',
      params: {
        propertyId: this.property ? this.property.id : undefined,
        faunaSurveyId: this.survey.id,
      },
    };
  }

  get editSurveyText() {
    if (this.isDraft || (this.isPublished && this.isProcessing)) {
      return 'Edit survey';
    }
    return 'Classifier';
  }

  get numberFormat() {
    return numberFormat;
  }

  get updateItem() {
    return throttle(
      () => cacheModule.getSurvey(this.surveyStub.id as string),
      3000,
    );
  }

  generateReport() {
    surveyReportDialog(this.surveyStub.id as string);
  }

  async getFaunaMediaPreview() {
    try {
      this.loading = true;
      const result = await useSpraypaintCache(
        FaunaMedia.where({
          faunaSurvey: this.survey.id,
          sns_status__in: ['uploaded', 'detected', 'predicted'],
        })
          .per(4)
          .order({ timestamp: 'asc' }),
      );
      this.faunaMediaThumbnails = result.data.map(fm => fm.thumbnail);
    } catch (e) {
      snackModule.setError({
        text: 'Could not load',
        errors: (e as ErrorResponse).response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  mounted() {
    userModule.getPropertyBySiteId(this.survey.surveySite.id as string);
    this.updateItem();
    this.getFaunaMediaPreview();
  }
}
