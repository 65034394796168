import { Model, Attr } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';

/*

This property 

Filters:
 - survey_property__in 
 - survey_site__in 
 - fauna_survey__in 
 - fauna_tag_group__in 
 - invasive 
 - fauna_tag
 - fauna_tag__in
 - tagger_type
 - tagger_type__in

Group By:
 - survey_property
 - survey_site
 - fauna_survey
 - fauna_tag
 - fauna_tag_group
 - invasive
 - tagged_by
 - tagger_type
 - tag_status

*/

@Model()
export default class FaunaStats extends ApplicationRecord {
  static jsonapiType = 'fauna-stats';

  static listHeaders() {
    return [
      {
        text: 'ID',
        value: 'id',
        width: 100,
      },
    ];
  }

  static scopeFactory() {
    return FaunaStats.includes([]);
  }

  @Attr() readonly surveySite: number | null;

  @Attr() readonly faunaSurvey: number | null;

  @Attr() readonly faunaTag: number | null;

  @Attr() readonly faunaTagGroup: number | null;

  @Attr() readonly taggedBy: number | null;

  @Attr() readonly taggerType: 'user' | 'ai' | 'expert' | null;

  @Attr() readonly tagStatus:
    | 'none'
    | 'conflict'
    | 'agreed'
    | 'resolved'
    | null;

  @Attr() readonly invasive: boolean | null;

  @Attr() readonly count: number;
}
