import { Model, Attr } from 'spraypaint';
import ApplicationRecord from '../ApplicationRecord';

/**
 * UsageSummary
 * need to provide filters like so:
 *
 * ?filter[start_date]=2024-01-01&filter[end_date]=2024-11-09
 */

@Model()
export default class UsageSummary extends ApplicationRecord {
  static 'jsonapiType' = 'usage-summaries';

  static 'listHeaders'() {
    return [
      {
        text: 'ID',
        value: 'id',
        width: 100,
      },
    ];
  }

  static 'scopeFactory'() {
    return UsageSummary.includes([]);
  }

  @Attr() 'newSurveyProperties': number;

  @Attr() 'newSurveySites': number;

  @Attr() 'newFaunaSurveys': number;

  @Attr() 'newFaunaMedia': number;

  @Attr() 'newFaunaMediaTags': number;
}
