





































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import PropertyCard from '@/components/property/PropertyCard.vue';
import SiteCard from '@/components/property/SiteCard.vue';
import StatTile from '@/components/common/StatTile.vue';

import PropertyStats from '@/components/property/PropertyStats.vue';
import SiteStats from '@/components/property/SiteStats.vue';

import GetStartedSite from '@/components/common/GetStartedSite.vue';
import GetStartedSurvey from '@/components/common/GetStartedSurvey.vue';

import FaunaStatsTile from '@/components/property/FaunaStatsTile.vue';

import FaunaStatsOverview from '@/components/property/FaunaStatsOverview.vue';
import UserStatsLines from '@/components/property/UserStatsLines.vue';
import FaunaStatsLines from '@/components/property/FaunaStatsLines.vue';
import SiteStatsLines from '@/components/property/SiteStatsLines.vue';
import PropertyMap from '@/components/property/PropertyMap.vue';

import inviteUserDialog from '@/invite-user-dialog';

import authModule from '@/store/Auth';
import cacheModule from '@/store/Cache';

import { FaunaStats, SurveyProperty } from '@/api';
import { useSpraypaintCache } from '@/cacheUtil';

import * as d3 from 'd3';

@Component({
  components: {
    PropertyCard,
    FaunaStatsTile,
    SiteCard,
    PropertyStats,
    SiteStats,
    StatTile,
    GetStartedSite,
    GetStartedSurvey,
    FaunaStatsOverview,
    UserStatsLines,
    FaunaStatsLines,
    SiteStatsLines,
    PropertyMap,
  },
})
export default class PropertyOverview extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  tagStatusColors = {
    none: '#90A1B1',
    conflict: 'var(--v-conflict-base)',
    agreed: 'var(--v-primary-base)',
    resolved: 'var(--v-resolved-base)',
  };

  tagStatusLabels = {
    none: 'Unidentified',
    conflict: 'Conflict',
    agreed: 'Agreed',
    resolved: 'Resolved',
  };

  color = d3.scaleOrdinal(d3.schemeTableau10);

  hideNone = false;

  percentageValues = false;

  rawStatsByTagStatus: FaunaStats[] = [];

  rawStatsByTag: FaunaStats[] = [];

  rawStatsByInvasive: FaunaStats[] = [];

  rawStatsByTagger: FaunaStats[] = [];

  rawStatsByTaggerType: FaunaStats[] = [];

  get canManageUsers() {
    return (
      (authModule.user && authModule.user.id === this.property.owner.id) ||
      authModule.isAdmin
    );
  }

  get siteId() {
    if (typeof this.$route.query.siteId === 'string') {
      return this.$route.query.siteId;
    }
    return null;
  }

  set siteId(s: string | null) {
    this.$router
      .replace({
        name: 'property-overview',
        params: { propertyId: this.property.id as string },
        query: { siteId: s || undefined },
      })
      .catch(() => null);
  }

  get selectedSite() {
    return this.siteId
      ? this.property.surveySites.find(site => site.id === this.siteId)
      : null;
  }

  // basic counts by tag status
  get noneCount() {
    const stat = this.rawStatsByTagStatus.find(s => s.tagStatus === 'none');
    return stat ? stat.count : 0;
  }

  get conflictCount() {
    const stat = this.rawStatsByTagStatus.find(s => s.tagStatus === 'conflict');
    return stat ? stat.count : 0;
  }

  get agreedCount() {
    const stat = this.rawStatsByTagStatus.find(s => s.tagStatus === 'agreed');
    return stat ? stat.count : 0;
  }

  get resolvedCount() {
    const stat = this.rawStatsByTagStatus.find(s => s.tagStatus === 'resolved');
    return stat ? stat.count : 0;
  }

  // first pie chart
  get statsByTagStatus() {
    return [
      {
        name: 'Unidentified',
        value: this.noneCount,
        color: this.tagStatusColors.none,
        pie: !this.hideNone,
        line: !this.hideNone,
        small: true,
      },
      {
        name: 'Conflict',
        value: this.conflictCount,
        color: this.tagStatusColors.conflict,
        pie: !this.hideNone,
        line: !this.hideNone,
        small: true,
      },
      {
        name: 'Agreed',
        value: this.agreedCount,
        color: this.tagStatusColors.agreed,
        pie: true,
        line: true,
      },
      {
        name: 'Resolved',
        value: this.resolvedCount,
        color: this.tagStatusColors.resolved,
        pie: true,
        line: true,
      },
    ].filter(d => d.value > 0);
  }

  get noneStats() {
    return [
      {
        name: 'Unidentified',
        value: this.noneCount,
        color: this.tagStatusColors.none,
        pie: !this.hideNone,
        line: !this.hideNone,
        small: !this.hideNone,
      },
      {
        name: 'Conflict',
        value: this.conflictCount,
        color: this.tagStatusColors.conflict,
        pie: !this.hideNone,
        line: !this.hideNone,
        small: !this.hideNone,
      },
    ];
  }

  get statsBySpecies() {
    return [
      ...this.noneStats,
      ...this.rawStatsByTag
        .filter(d => !!d.faunaTag)
        .map((d, i) => {
          const tag = d.faunaTag
            ? cacheModule.faunaTagsById(d.faunaTag.toString())
            : null;
          return {
            name: tag ? tag.name : 'Unknown',
            value: d.count,
            color: this.color(i.toString()),
            pie: true,
            line: true,
          };
        }),
    ];
  }

  get statsByInvasive() {
    return [
      ...this.noneStats,
      ...this.rawStatsByInvasive
        .filter(d => d.invasive !== null)
        .map((d, i) => ({
          name: d.invasive ? 'Invasive' : 'Native',
          value: d.count,
          color: this.color(i.toString()),
          pie: true,
          line: true,
        })),
    ];
  }

  get statsByTagger() {
    return [
      ...this.noneStats,
      ...this.rawStatsByTagger
        .filter(d => !!d.taggedBy)
        .map((d, i) => ({
          name: d.taggedBy ? 'Name' : 'Unknown',
          value: d.count,
          color: this.color(i.toString()),
          pie: true,
          line: true,
        })),
    ];
  }

  get statsByTaggerType() {
    return [
      ...this.noneStats,
      ...this.rawStatsByTaggerType
        .filter(d => !!d.taggerType)
        .map((d, i) => ({
          name: d.taggerType || 'Unknown',
          value: d.count,
          color: this.color(i.toString()),
          pie: true,
          line: true,
        })),
    ];
  }

  get stats() {
    return [
      {
        label: 'Tags by Status',
        data: this.statsByTagStatus,
      },
      {
        label: 'Tags by Species',
        data: this.statsBySpecies,
      },
      {
        label: 'Tags by Invasive',
        data: this.statsByInvasive,
      },
      // {
      //   label: 'Tags by Tagger',
      //   data: this.statsByTagger,
      // },
      // {
      //   label: 'Tags by Tagger Type',
      //   data: this.statsByTaggerType,
      // },
    ];
  }

  async getBboxStats() {
    const [
      rawStatsByTagStatus,
      rawStatsByTag,
      rawStatsByInvasive,
      // rawStatsByTagger,
      // rawStatsByTaggerType,
    ] = await Promise.all([
      useSpraypaintCache(
        FaunaStats.where({
          survey_property__in: this.property.id as string,
          group_by: ['tag_status'],
        }),
      ).then(r => r.data),
      useSpraypaintCache(
        FaunaStats.where({
          survey_property__in: this.property.id as string,
          group_by: ['fauna_tag'],
        }),
      ).then(r => r.data),
      useSpraypaintCache(
        FaunaStats.where({
          survey_property__in: this.property.id as string,
          group_by: ['invasive'],
        }),
      ).then(r => r.data),
      // useSpraypaintCache(
      //   FaunaStats.where({
      //     survey_property__in: this.property.id as string,
      //     group_by: ['tagged_by'],
      //   }),
      // ).then(r => r.data),
      // useSpraypaintCache(
      //   FaunaStats.where({
      //     survey_property__in: this.property.id as string,
      //     group_by: ['tagger_type'],
      //   }),
      // ).then(r => r.data),
    ]);

    this.rawStatsByTagStatus = rawStatsByTagStatus;
    this.rawStatsByTag = rawStatsByTag;
    this.rawStatsByInvasive = rawStatsByInvasive;
    // this.rawStatsByTagger = rawStatsByTagger;
    // this.rawStatsByTaggerType = rawStatsByTaggerType;
  }

  async inviteUser() {
    await inviteUserDialog(this.property, null);
  }

  mounted() {
    this.getBboxStats();
  }

  @Watch('property.id')
  propertyIdChanged() {
    this.getBboxStats();
  }
}
