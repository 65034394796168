






















































import { Component, Vue, Watch } from 'vue-property-decorator';

import { debounce } from 'lodash';

import PageHeader from '@/components/common/PageHeader.vue';
import PropertyListItem from '@/components/property/PropertyListItem.vue';
import FilterButtonContainer from '@/components/common/FilterButtonContainer.vue';
import FilterButton from '@/components/common/FilterButton.vue';
import SortButton from '@/components/common/SortButton.vue';
import CompactPagination from '@/components/upload/CompactPagination.vue';

import { SurveyProperty } from '@/api';
import { useSpraypaintCache } from '@/cacheUtil';

import snackModule from '@/store/Snack';

import deleteDialog from '@/delete-dialog';

@Component({
  components: {
    PageHeader,
    FilterButtonContainer,
    FilterButton,
    SortButton,
    PropertyListItem,
    CompactPagination,
  },
})
export default class AdminDashPropertyList extends Vue {
  loading = false;

  total = 0;

  properties: SurveyProperty[] = [];

  itemsPerPage = 10;

  get sortItems() {
    return [
      {
        label: 'Created',
        value: 'createdAt',
      },
      {
        label: 'Name',
        value: 'name',
      },
    ];
  }

  get filterItems() {
    return [
      // TODO: filter by usage stats
      // i.e. has site, has survey, has photos, has tags
      {
        label: 'Stickybeak',
        relationship: 'sb_compatible',
        queryParam: 'sb-compatible',
        items: [
          {
            label: 'Incompatible',
            value: 'incompatible',
          },
          {
            label: 'Handling',
            value: 'handling',
          },
          {
            label: 'Normalized',
            value: 'normalized',
          },
          {
            label: 'Compatible',
            value: 'compatible',
          },
        ],
        multiple: false,
      },
    ];
  }

  get whereClause() {
    const clause: { [key: string]: unknown } = {};
    this.filterItems.forEach(filterItem => {
      clause[filterItem.relationship] =
        this.$route.query[filterItem.queryParam] || undefined;
    });
    return {
      ...clause,
    };
  }

  get search() {
    return (this.$route.query.search as string) || '';
  }

  set search(s: string) {
    this.$router.replace({
      query: {
        ...this.$route.query,
        search: s,
        page: undefined,
      },
    });
  }

  get page() {
    return parseInt((this.$route.query.page as string) || '1', 10);
  }

  set page(p: number) {
    this.$router.replace({
      query: { ...this.$route.query, page: p.toString() },
    });
  }

  get pageCount() {
    return Math.ceil(this.total / this.itemsPerPage);
  }

  get displayCount() {
    return `Showing ${1 + (this.page - 1) * this.itemsPerPage} - ${Math.min(
      this.page * this.itemsPerPage,
      this.total,
    )} of ${this.total}`;
  }

  get sort(): { [key: string]: 'asc' | 'desc' } {
    if (!this.$route.query.sort) {
      return { createdAt: 'desc' };
    }
    let sort = this.$route.query.sort as string;
    let asc = true;
    if (sort.startsWith('-')) {
      sort = sort.replace(/^-/, '');
      asc = false;
    }
    return { [sort]: asc ? 'asc' : 'desc' };
  }

  get update() {
    return debounce(this.doUpdate, 300);
  }

  async doUpdate() {
    this.loading = true;
    this.properties = [];
    try {
      const result = await useSpraypaintCache(
        SurveyProperty.select(['id', 'name', 'createdAt', 'sbCompatible'])
          .where({
            ...this.whereClause,
            search: this.search,
            skip_stats: true,
          })
          .order(this.sort)
          .page(this.page)
          .per(this.itemsPerPage),
      );
      this.properties = result.data;
      this.total = result.meta.pagination.count;
    } catch (e) {
      this.properties = [];
      snackModule.setError({
        text: 'Could not load',
        errors: (e as ErrorResponse).response.errors,
      });
    } finally {
      this.loading = false;
    }
  }

  async deleteItem(property: SurveyProperty) {
    const confirm = await deleteDialog(
      property.name,
      'property',
      property.name,
    );
    if (!confirm) {
      return;
    }
    try {
      const item = property.dup();
      await item.destroy();
      snackModule.setSuccess(`${item.name} deleted`);
      this.update();
    } catch (e) {
      snackModule.setError({
        text: 'Could not delete',
        errors: (e as ErrorResponse).response.errors,
      });
    }
  }

  mounted() {
    this.update();
  }

  @Watch('page')
  @Watch('sort')
  @Watch('search')
  optionsChanged() {
    this.update();
  }
}
