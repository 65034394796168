




































import { Component, Vue, Prop } from 'vue-property-decorator';

import LineIndicator from '@/components/common/LineIndicator.vue';
import PieChart from '@/components/common/PieChart.vue';

import { numberFormat } from '@/util';

@Component({
  components: {
    LineIndicator,
    PieChart,
  },
})
export default class FaunaStatsTile extends Vue {
  @Prop({ default: '' }) readonly title: string;

  @Prop({ default: false }) readonly percentageValues: boolean;

  showAll = false;

  limit = 4;

  @Prop({ required: true }) readonly data: {
    name: string;
    value: number;
    color: string;
    pie?: boolean;
    line?: boolean;
    small?: boolean;
  }[];

  get pieData() {
    return this.data.filter(d => d.pie);
  }

  get lineData() {
    return this.data.filter(d => d.line).sort((a, b) => b.value - a.value);
  }

  get shownLineData() {
    return this.showAll ? this.lineData : this.lineData.slice(0, this.limit);
  }

  get numberFormat() {
    return numberFormat;
  }

  get max() {
    return this.lineData.reduce((sum, item) => sum + item.value, 0);
  }
}
