












import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import * as d3 from 'd3';
import { numberFormat } from '@/util';

interface PieDatum {
  name: string;
  value: number;
  color?: string;
  pie?: boolean;
  line?: boolean;
  small?: boolean;
}

@Component({})
export default class PieChart extends Vue {
  @Prop({ required: true }) readonly data: Array<PieDatum>;

  @Prop({ default: false }) readonly percent: boolean;

  title = '';

  subtitle = '';

  get numberFormat() {
    return numberFormat;
  }

  get total() {
    return this.data.reduce((sum, item) => sum + item.value, 0);
  }

  draw() {
    const pieEl = this.$refs.pieEl as HTMLElement;

    d3.select(pieEl)
      .selectAll('*')
      .remove();

    const width = pieEl.offsetWidth;
    const height = pieEl.offsetHeight;

    const svg = d3
      .select(pieEl)
      .append('svg')
      .attr('viewBox', [-width / 2, -height / 2, width, height]);

    const radius = Math.min(width, height) / 2;
    const color = d3.scaleOrdinal(d3.schemeCategory10);

    const arc = d3
      .arc<{ data: PieDatum }>()
      .cornerRadius(2)
      .innerRadius(d => (d.data.small ? radius * 0.77 : radius * 0.7))
      .outerRadius(d => (d.data.small ? radius * 0.83 : radius * 0.9));

    const pie = d3
      .pie<PieDatum>()
      .sort(null)
      .padAngle(0.0075)
      .value(d => d.value)(this.data);

    svg
      .append('g')
      .selectAll('path')
      .data(pie)
      .join('path')
      .attr('fill', (d, i) => d.data.color || color(i.toString()))
      .attr('d', arc)
      .on('mouseenter', (event, d) => {
        this.title = d.data.name;
        this.subtitle = this.percent
          ? `${((d.data.value / this.total) * 100).toFixed(2)}%`
          : numberFormat(d.data.value);
      })
      .on('mouseleave', () => {
        this.title = 'Total';
        this.subtitle = numberFormat(this.total);
      });
  }

  mounted() {
    this.draw();
    this.title = 'Total';
    this.subtitle = numberFormat(this.total);
  }

  @Watch('data')
  dataChanged() {
    this.draw();
    this.title = 'Total';
    this.subtitle = numberFormat(this.total);
  }
}
