import { Vue } from 'vue-property-decorator';

import vuetify from '@/plugins/vuetify';

import SurveyReportDialog from '@/components/common/SurveyReportDialog.vue';

const SurveyReportDialogClass = Vue.extend(SurveyReportDialog);

export default function surveyReportDialog(
  surveyId: string,
): Promise<string | number | null> {
  return new Promise(resolve => {
    const dialog = new SurveyReportDialogClass({
      vuetify,
      propsData: { surveyId },
    });
    dialog.$on('close', () => {
      dialog.$destroy();
      resolve(null);
    });
    dialog.$mount();
  });
}
