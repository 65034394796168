













































import { Component, Vue, Watch } from 'vue-property-decorator';

import { SurveyProperty, PropertyUser } from '@/api';
import authModule from '@/store/Auth';
import { debounce } from 'lodash';

@Component({ components: {} })
export default class SortButton extends Vue {
  showMenu = false; // menu

  items: SurveyProperty[] = [];

  search = '';

  get selected() {
    return this.$route.params.propertyId;
  }

  get isAdmin() {
    return authModule.isAdmin;
  }

  get myProperties() {
    return authModule.myProperties;
  }

  selectProperty(property: SurveyProperty) {
    this.$router.push({
      name: 'property-overview',
      params: { propertyId: property.id as string },
    });
  }

  async doUpdate() {
    if (this.isAdmin) {
      this.items = (
        await SurveyProperty.select(['id', 'name'])
          .where({ skip_stats: true, search: this.search })
          .per(1000)
          .all()
      ).data;
    } else {
      this.items = authModule.myProperties;
    }
  }

  get update() {
    return debounce(this.doUpdate, 300);
  }

  @Watch('search', { immediate: true })
  searchChanged() {
    this.update();
  }
}
