

























import { Component, Vue, Watch } from 'vue-property-decorator';

import { DetectionBbox, TaggerType } from '@/api';

import BboxListItem from '@/components/property/BboxListItem.vue';
import GetStartedSite from '@/components/common/GetStartedSite.vue';
import GetStartedSurvey from '@/components/common/GetStartedSurvey.vue';
import FilterButtonContainer from '@/components/common/FilterButtonContainer.vue';
import FilterButton from '@/components/common/FilterButton.vue';
import CompactPagination from '@/components/upload/CompactPagination.vue';

import snackModule from '@/store/Snack';
import cacheModule from '@/store/Cache';

import { debounce } from 'lodash';
import { useSpraypaintCache } from '@/cacheUtil';

@Component({
  components: {
    BboxListItem,
    GetStartedSite,
    GetStartedSurvey,
    FilterButtonContainer,
    FilterButton,
    CompactPagination,
  },
})
export default class AdminDashBboxList extends Vue {
  loading = false;

  firstLoad = true;

  total = 0;

  bboxes: DetectionBbox[] = [];

  itemsPerPage = 20;

  get page() {
    return parseInt((this.$route.query.page as string) || '1', 10);
  }

  set page(p: number) {
    this.$router.replace({
      query: { ...this.$route.query, page: p.toString() },
    });
  }

  get pageCount() {
    return Math.ceil(this.total / this.itemsPerPage);
  }

  get displayCount() {
    return `Showing ${1 + (this.page - 1) * this.itemsPerPage} - ${Math.min(
      this.page * this.itemsPerPage,
      this.total,
    )} of ${this.total}`;
  }

  get update() {
    return debounce(this.doUpdate, 300);
  }

  get surveySiteItems() {
    return [];
    // return this.property.surveySites.map(site => ({
    //   label: site.name,
    //   value: site.id,
    // }));
  }

  get faunaTagItems() {
    return cacheModule.faunaTags.map(ft => ({
      label: ft.name,
      value: ft.id,
    }));
  }

  get statusItems() {
    return [
      { label: 'Agreed', value: 'agreed' },
      { label: 'Resolved', value: 'resolved' },
      { label: 'Conflict', value: 'conflict' },
    ];
  }

  get finalTaggerTypeItems() {
    return [
      { label: 'None', value: null },
      { label: 'AI', value: TaggerType.ai },
      { label: 'Expert', value: TaggerType.expert },
      { label: 'User', value: TaggerType.user },
    ];
  }

  get filterItems() {
    return [
      {
        label: 'Status',
        relationship: 'tag_status__in',
        queryParam: 'tag-status',
        items: this.statusItems,
        multiple: true,
      },
      {
        label: 'Resolved By',
        relationship: 'final_tagger_type__in',
        queryParam: 'final-tag',
        items: this.finalTaggerTypeItems,
        multiple: true,
      },
    ];
  }

  get whereClause() {
    const clause: { [key: string]: unknown } = {};
    this.filterItems.forEach(filterItem => {
      clause[filterItem.relationship] =
        this.$route.query[filterItem.queryParam] || undefined;
    });

    if (
      !clause.tag_status__in ||
      (clause.tag_status__in as string[]).length === 0
    ) {
      clause.tag_status__in = ['conflict', 'agreed', 'resolved'];
    }

    return {
      ...clause,
    };
  }

  async doUpdate() {
    this.loading = true;
    try {
      const result = await useSpraypaintCache(
        DetectionBbox.includes(['faunaMediaTags', 'faunaMedia.faunaSurvey'])
          .where(this.whereClause)
          .page(this.page)
          .per(this.itemsPerPage)
          .order({ createdAt: 'desc' }),
      );
      this.bboxes = result.data;
      this.total = result.meta.pagination.count;
    } catch (e) {
      this.bboxes = [];
      snackModule.setError({
        text: 'Could not load',
        errors: (e as ErrorResponse).response.errors,
      });
    } finally {
      this.firstLoad = false;
      this.loading = false;
    }
  }

  mounted() {
    this.update();
  }

  @Watch('page')
  @Watch('whereClause')
  optionsChanged() {
    this.update();
  }
}
