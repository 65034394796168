


















import { Component, Vue, Prop } from 'vue-property-decorator';

import { DetectionBbox } from '@/api';

import UserAvatar from '@/components/common/UserAvatar.vue';
import cacheModule from '@/store/Cache';

@Component({
  components: {
    UserAvatar,
  },
})
export default class BboxDialog extends Vue {
  @Prop({ required: true }) readonly bbox: DetectionBbox;

  get categorySentenceCase() {
    const cat = this.bbox.category;
    return `${cat.charAt(0).toUpperCase()}${cat.slice(1).toLowerCase()}`;
  }

  get predictionData() {
    return this.bbox.prediction.map(prediction => {
      const tag = cacheModule.faunaTags.find(
        ft => ft.code === prediction.label,
      );
      return {
        label: tag ? tag.name : prediction.label,
        conf: `${(100 * prediction.conf).toFixed(2)}%`,
      };
    });
  }
}
