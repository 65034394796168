






















import { Component, Vue, Prop } from 'vue-property-decorator';

import { FaunaSurvey, FaunaSurveyReport } from '@/api';
import snackModule from '@/store/Snack';

@Component({ components: {} })
export default class SurveyReportDialog extends Vue {
  @Prop({ required: true }) readonly surveyId: string;

  value = true;

  get show() {
    return this.value;
  }

  set show(v) {
    this.$emit('close');
    this.value = v;
  }

  async generateReport() {
    try {
      const report = new FaunaSurveyReport({
        faunaSurvey: new FaunaSurvey({ id: this.surveyId }),
      });
      await report.save({ with: ['faunaSurvey.id'] });
      snackModule.setSuccess(
        "Request received successfully. We're generating your report and you should receive an email shortly.",
      );
      this.show = false;
    } catch (e) {
      snackModule.setError({
        text: 'Error generating report',
        errors: (e as ErrorResponse).response.errors,
      });
    }
  }
}
