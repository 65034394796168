












































import { Component, Vue, Prop } from 'vue-property-decorator';
import { numberFormat } from '@/util';

@Component({ components: {} })
export default class LineIndicator extends Vue {
  @Prop({ default: false }) readonly labels: boolean;

  @Prop({ default: -1 }) readonly index: number;

  @Prop({ default: '' }) readonly color: number;

  @Prop({ default: '' }) readonly thumb: string;

  @Prop({ default: '' }) readonly title: string;

  @Prop({ required: true }) readonly value: number;

  @Prop({ required: true }) readonly max: number;

  @Prop({ default: false }) readonly percent: boolean;

  get lineStyle() {
    return `width: ${Math.min(
      100,
      (100 * this.value) / this.max,
    )}%; background-color: ${this.color ? this.color : 'black'};`;
  }

  get numberFormat() {
    return numberFormat;
  }

  get valueText() {
    return this.percent
      ? `${((100 * this.value) / this.max).toFixed(2)}%`
      : this.numberFormat(this.value);
  }
}
