/* eslint-disable no-underscore-dangle */
import {
  Module,
  VuexModule,
  Mutation,
  Action,
  getModule,
} from 'vuex-module-decorators';

import {
  FocalLandscape,
  FaunaTag,
  FaunaTagGroup,
  Project,
  SurveyProperty,
  PublicUser,
  User,
  PropertyUser,
  FaunaSurvey,
} from '@/api';

import store from '@/store';
import Vue from 'vue';

const cacheAge = 3 * 60 * 1000;
const cacheRefetchAge = 10 * 1000;

@Module({ dynamic: true, namespaced: true, name: 'cache', store })
class CacheModule extends VuexModule {
  ready = false;

  _faunaTags: FaunaTag[] = [];

  _mappedFaunaTags: { [key: string]: FaunaTag } = {};

  _faunaTagGroups: FaunaTagGroup[] = [];

  _focalLandscapes: FocalLandscape[] = [];

  _faunaTagsReady = false;

  _faunaTagGroupsReady = false;

  _focalLandscapeReady = false;

  get project() {
    return new Project({ id: 1 });
  }

  get tagsReady() {
    return this._faunaTagsReady && this._faunaTagGroupsReady;
  }

  get faunaTags() {
    return this._faunaTags;
  }

  get faunaTagGroups() {
    return this._faunaTagGroups;
  }

  get faunaTagsById() {
    return (id: string) => this._mappedFaunaTags[id];
  }

  get faunaTagsByGroupId() {
    return (groupId: string) =>
      this._faunaTags
        .filter(tag => tag.faunaTagGroup.id === groupId)
        .sort((a, b) => a.name.localeCompare(b.name));
  }

  get faunaTagFilterItems() {
    return this.faunaTags.map(faunaTag => ({
      label: faunaTag.name,
      value: faunaTag.id,
    }));
  }

  get faunaTagGroupFilterItems() {
    return this.faunaTagGroups.map(group => ({
      label: group.name,
      value: group.id,
    }));
  }

  get focalLandscapes() {
    return this._focalLandscapes;
  }

  get focalLandscapeFilterItems() {
    return this.focalLandscapes.map(fl => ({
      label: fl.name,
      value: fl.id,
    }));
  }

  // mutations

  @Mutation
  setFaunaTags(faunaTags: FaunaTag[]) {
    this._faunaTags = faunaTags;
    faunaTags.forEach(tag => {
      Vue.set(this._mappedFaunaTags, tag.id as string, tag);
    });
    this._faunaTagsReady = true;
  }

  @Mutation
  setFaunaTagGroups(faunaTagGroups: FaunaTagGroup[]) {
    this._faunaTagGroups = faunaTagGroups;
    this._faunaTagGroupsReady = true;
  }

  @Mutation
  setFocalLandscapes(focalLandscapes: FocalLandscape[]) {
    this._focalLandscapes = focalLandscapes;
    this._focalLandscapeReady = true;
  }

  // actions

  @Action({ rawError: true })
  init() {
    this.getFocalLandscapes();
    this.getFaunaTagsAndGroups();
  }

  @Action({ rawError: true })
  async getFaunaTagsAndGroups() {
    if (this.tagsReady) return;
    const faunaTags = (await FaunaTag.all()).data;
    const faunaTagGroup = (await FaunaTagGroup.all()).data;
    this.setFaunaTags(faunaTags);
    this.setFaunaTagGroups(faunaTagGroup);
  }

  @Action({ rawError: true })
  async getFocalLandscapes() {
    if (this._focalLandscapeReady) return;
    const focalLandscapes = (await FocalLandscape.scopeFactory().all()).data;
    this.setFocalLandscapes(focalLandscapes);
  }

  // property single cache
  // --------------------------------------------
  _properties: { [id: string]: SurveyProperty } = {};

  _propertiesTTL: { [id: string]: number } = {};

  get property() {
    return (id: string) => this._properties[id];
  }

  @Mutation
  setProperty(property: SurveyProperty) {
    Vue.set(this._properties, property.id as string, property);
    Vue.set(this._propertiesTTL, property.id as string, Date.now());

    Object.entries(this._propertiesTTL).forEach(([id, ttl]) => {
      if (ttl < Date.now() - cacheAge) {
        Vue.delete(this._properties, id);
        Vue.delete(this._propertiesTTL, id);
      }
    });
  }

  @Action({ rawError: true })
  async getProperty(id: string) {
    if (
      this._propertiesTTL[id] &&
      this._propertiesTTL[id] > Date.now() - cacheRefetchAge
    ) {
      return this._properties[id];
    }
    const newProperty = await SurveyProperty.includes(['surveySites']).find(id);
    this.setProperty(newProperty.data);
    return newProperty;
  }

  // survey single cache
  // --------------------------------------------
  _surveys: { [id: string]: FaunaSurvey } = {};

  _surveysTTL: { [id: string]: number } = {};

  get survey() {
    return (id: string) => this._surveys[id];
  }

  @Mutation
  setSurvey(survey: FaunaSurvey) {
    Vue.set(this._surveys, survey.id as string, survey);
    Vue.set(this._surveysTTL, survey.id as string, Date.now());

    Object.entries(this._surveysTTL).forEach(([id, ttl]) => {
      if (ttl < Date.now() - cacheAge) {
        Vue.delete(this._surveys, id);
        Vue.delete(this._surveysTTL, id);
      }
    });
  }

  @Action({ rawError: true })
  async getSurvey(id: string) {
    if (
      this._surveysTTL[id] &&
      this._surveysTTL[id] > Date.now() - cacheRefetchAge
    ) {
      return this._surveys[id];
    }
    const newSurvey = await FaunaSurvey.find(id);
    this.setSurvey(newSurvey.data);
    return newSurvey;
  }

  // property users cache
  // a cache for property users for a given property
  // --------------------------------------------
  _propertyUsers: { [propertyId: string]: User[] } = {};

  _propertyUsersTTL: { [propertyId: string]: number } = {};

  get propertyUsers() {
    return (propertyId: string) => this._propertyUsers[propertyId];
  }

  @Mutation
  setPropertyUsers({
    propertyId,
    propertyUsers,
  }: {
    propertyId: string;
    propertyUsers: User[];
  }) {
    Vue.set(this._propertyUsers, propertyId, propertyUsers);
    Vue.set(this._propertyUsersTTL, propertyId, Date.now());

    Object.entries(this._propertyUsersTTL).forEach(([id, ttl]) => {
      if (ttl < Date.now() - cacheAge) {
        Vue.delete(this._propertyUsers, id);
        Vue.delete(this._propertyUsersTTL, id);
      }
    });
  }

  @Action({ rawError: true })
  async getPropertyUsers(propertyId: string) {
    if (
      this._propertyUsersTTL[propertyId] &&
      this._propertyUsersTTL[propertyId] > Date.now() - cacheRefetchAge
    ) {
      return this._propertyUsers[propertyId];
    }
    const property = await SurveyProperty.select(['id', 'owner'])
      .includes(['owner'])
      .find(propertyId);
    const propertyUsers = await PropertyUser.includes(['user'])
      .where({
        SurveyProperty: propertyId,
      })
      .all();

    this.setPropertyUsers({
      propertyId,
      propertyUsers: [
        property.data.owner,
        ...propertyUsers.data.map(pu => pu.user),
      ],
    });
    return propertyUsers;
  }
}

export default getModule(CacheModule, store);
