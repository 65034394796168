





























































import { Component, Vue, Prop, VModel, Watch } from 'vue-property-decorator';

import { BatchUploadResult } from '@/components/upload/batch-uploader';

import AlertAccordion from '@/components/common/AlertAccordion.vue';

@Component({
  components: {
    AlertAccordion,
  },
})
export default class FaunaMediaUploadDialog extends Vue {
  @Prop({ required: true }) readonly uploader: BatchUploadResult | null;

  @VModel() show: boolean;

  uploadCompleteCount = 0;

  uploadErrorCount = 0;

  uploadTotalCount = 0;

  errors: string[] = [];

  uploadStatus: ReturnType<BatchUploadResult['status']> | 'none' = 'none';

  get inProgress() {
    return (
      this.uploadStatus === 'preparing' || this.uploadStatus === 'uploading'
    );
  }

  get uploadProgress() {
    return (
      (100 * (this.uploadCompleteCount + this.uploadErrorCount)) /
      this.uploadTotalCount
    );
  }

  get uploadTitle() {
    switch (this.uploadStatus) {
      case 'pending':
      case 'preparing':
        return 'Preparing upload';
      case 'uploading':
        return 'Upload in progress...';
      case 'complete':
        return 'Upload complete!';
      case 'error':
        return 'Upload complete... with some errors!';
      default:
        return 'Unknown';
    }
  }

  get uploadSubtitle() {
    switch (this.uploadStatus) {
      case 'pending':
        return 'Pending';
      case 'preparing':
        return 'Preparing upload';
      case 'uploading':
        return `${this.uploadCompleteCount + this.uploadErrorCount} of ${
          this.uploadTotalCount
        } uploaded`;
      case 'complete':
        return 'Upload complete!';
      case 'error':
        return 'Upload complete... with some errors!';
      default:
        return 'Unknown';
    }
  }

  retryFailedUploads() {
    if (!this.uploader) {
      console.warn('no uploader');
      return;
    }

    this.uploader.clearCompleted();
    this.uploader.retryAll();
  }

  handleQueueProgress() {
    if (!this.uploader) {
      return;
    }
    this.errors = [];
    this.uploadCompleteCount = this.uploader.totalCompleted();
    this.uploadErrorCount = this.uploader.totalError();
    this.uploadTotalCount = this.uploader.queue().length;
    this.uploadStatus = this.uploader.status();
  }

  handleQueueComplete() {
    if (!this.uploader) {
      return;
    }
    this.handleQueueProgress();
    if (this.uploadErrorCount) {
      this.errors = this.uploader
        .queue()
        .filter(item => item.status() === 'error')
        .map(item => item.key);
    }
  }

  close() {
    this.show = false;
  }

  @Watch('uploader')
  uploaderChanged() {
    if (this.uploader) {
      this.show = true;
      this.handleQueueProgress();
      this.uploader.addListener('preparestart', this.handleQueueProgress);
      this.uploader.addListener('queuestart', this.handleQueueProgress);
      this.uploader.addListener('queueprogress', this.handleQueueProgress);
      this.uploader.addListener('queuecomplete', this.handleQueueComplete);
    } else {
      this.errors = [];
      this.uploadCompleteCount = 0;
      this.uploadErrorCount = 0;
      this.uploadTotalCount = 0;
      this.uploadStatus = 'none';
    }
  }
}
