




































































































import {
  Component,
  Prop,
  Vue,
  VModel,
  InjectReactive,
} from 'vue-property-decorator';
import { DetectionBbox, FaunaTag } from '@/api';

import Classifier from '@/components/classifier/Classifier.vue';
import cacheModule from '@/store/Cache';

@Component({
  components: {},
})
export default class BBoxContextMenu extends Vue {
  @Prop({ required: true }) readonly bbox: DetectionBbox;

  @Prop({ required: true }) readonly x: number;

  @Prop({ required: true }) readonly y: number;

  @VModel() active: boolean;

  @InjectReactive() classifier: Classifier;

  windowH = 0;

  windowW = 0;

  /**
   * the menu is positioned above the bbox if the bbox is above the center of the window
   */
  get top() {
    return this.y > this.windowH / 2;
  }

  /**
   * the menu is positioned to the left of the bbox if the bbox is to the left of 3/4 of the window
   */
  get left() {
    return this.x > this.windowW * 0.75;
  }

  get unknown() {
    return cacheModule.faunaTags.find(ft => ft.code === 'unknown_animal');
  }

  /**
   * organise the fauna tags by group
   */
  get faunaTagGroups() {
    return cacheModule.faunaTagGroups
      .map((group, i) => ({
        active: i === 0,
        group,
        children: cacheModule
          .faunaTagsByGroupId(group.id as string)
          .filter(ft => ft.code !== 'unknown_animal'),
      }))
      .filter(group => group.children.length);
  }

  /**
   * handler for selecting a tag
   */
  selectTag(tag: FaunaTag) {
    this.$emit('select-tag', tag, this.bbox);

    // add the tag to recent tags
    const i = this.classifier.recentTags.indexOf(tag);
    if (i !== -1) {
      this.classifier.recentTags.splice(i, 1);
    }
    if (this.classifier.recentTags.length > 4) {
      this.classifier.recentTags.splice(4, 1);
    }
    this.classifier.recentTags.unshift(tag);
  }

  /**
   * handler for selecting a blank bbox
   */
  selectBlank() {
    this.$emit('select-blank', this.bbox);
  }

  /**
   * handler for selecting classification data
   */
  selectInfo() {
    this.$emit('select-info', this.bbox);
  }

  /**
   * handle window resize
   */
  onResize() {
    this.windowH = window.innerHeight;
    this.windowW = window.innerWidth;
    this.active = false;
  }

  onKeyDown(e: KeyboardEvent) {
    if (!this.active) {
      return;
    }

    if (e.key === 'Escape') {
      e.preventDefault();
      this.active = false;
      return;
    }

    const tag = cacheModule.faunaTags.find(item => item.keycode === e.key);
    if (tag) {
      e.preventDefault();
      this.selectTag(tag);
    }
  }

  created() {
    // add resize listener
    window.addEventListener('resize', this.onResize);
    window.addEventListener('keydown', this.onKeyDown);
  }

  beforeDestroy() {
    // remove resize listener
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('keydown', this.onKeyDown);
  }

  mounted() {
    // trigger resize handler
    this.onResize();
  }
}
