var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-16"},[(!_vm.property.surveySites.length)?_c('div',{staticClass:"mt-16"},[_c('get-started-site')],1):(_vm.survey)?_c('div',[_vm._m(0),_c('div',[_c('div',{staticClass:"survey-edit-step",class:{
          'survey-edit-step--active': _vm.activeStep === 1,
          'survey-edit-step--complete': _vm.step1Complete,
        }},[_c('div',{staticClass:"survey-edit-step__inner"},[_c('div',{staticClass:"survey-edit-step__icon"},[(!_vm.step1Complete)?_c('h4',[_vm._v("1")]):_c('v-icon',[_vm._v("mdi-check")])],1),_vm._m(1)]),_c('div',{staticClass:"d-flex align-center gap-4 mt-8"},[_c('div',{staticClass:"survey-edit-step-ctrl"},[_c('v-select',{attrs:{"items":_vm.property.surveySites,"label":"Site","item-text":"name","item-value":"id","outlined":"","hide-details":"","disabled":_vm.isPublished},model:{value:(_vm.selectedSiteId),callback:function ($$v) {_vm.selectedSiteId=$$v},expression:"selectedSiteId"}})],1),_c('div',{staticClass:"survey-edit-step-ctrl"},[_c('date-picker',{attrs:{"outlined":"","label":"Start Date","disabled":_vm.isPublished,"hide-details":""},model:{value:(_vm.startTimestamp),callback:function ($$v) {_vm.startTimestamp=$$v},expression:"startTimestamp"}})],1),_c('div',{staticClass:"survey-edit-step-ctrl"},[_c('date-picker',{attrs:{"outlined":"","label":"End Date","disabled":_vm.isPublished,"endOfDay":true,"hide-details":""},model:{value:(_vm.endTimestamp),callback:function ($$v) {_vm.endTimestamp=$$v},expression:"endTimestamp"}})],1)]),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"mt-4",attrs:{"depressed":"","large":"","color":"primary","disabled":!_vm.basicSurveyDataIsValid || !_vm.basicSurveyDataIsDirty},on:{"click":_vm.saveBasicSurveyData}},[_vm._v(" Save ")])],1)]),_c('fauna-media-uploader',{staticClass:"mt-8",class:{
          'survey-edit-step--active': _vm.activeStep === 2,
          'survey-edit-step--complete': _vm.step2Complete,
          'survey-edit-step--disabled': !_vm.step1Complete,
        },attrs:{"disabled":!_vm.step1Complete || _vm.isPublished,"complete":_vm.step2Complete},on:{"upload":_vm.startUpload},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var initFileUpload = ref.upload;
return [(!_vm.uploader && _vm.survey.totalCount)?_c('alert-accordion',[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("mdi-information-outline")]),_c('div',{staticClass:"flex-grow-1"},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.survey.uploadedWithoutErrorsCount))+" photos successfully uploaded ")]),_c('v-btn',{attrs:{"depressed":"","outlined":"","color":"primary"},on:{"click":_vm.showSuccessGallery}},[_vm._v(" View Images ")])],1):_vm._e(),(!_vm.uploader && _vm.survey.problematicCount)?_c('alert-accordion',{staticClass:"alert-accordion--error mt-4",attrs:{"icon":"right"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"mb-4"},[_c('p',[_vm._v(" We can't find a number of your photos. The most common reason for this is that they failed to upload. The easiest way to fix this is to try again. Note that we won't upload photos that have already been uploaded, so feel free to select your entire survey again. ")]),_c('ul',[_vm._l((_vm.problematicFaunaMedia),function(filename){return _c('li',{key:filename},[_vm._v(" "+_vm._s(filename)+" ")])}),(
                        _vm.survey.problematicCount > _vm.problematicFaunaMedia.length
                      )?_c('li',[_vm._v(" and "+_vm._s(_vm.survey.problematicCount - _vm.problematicFaunaMedia.length)+" others ")]):_vm._e()],2)]),_c('div',{staticClass:"d-flex align-center gap-2"},[_c('v-btn',{attrs:{"depressed":"","outlined":"","color":"conflict"},on:{"click":_vm.showProblematicGallery}},[_vm._v(" View images ")]),_c('v-btn',{attrs:{"depressed":"","outlined":"","color":"conflict"},on:{"click":_vm.deletePending}},[_vm._v(" Discard these images ")]),_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":initFileUpload}},[_vm._v(" Re-upload these images ")])],1)])]},proxy:true}],null,true)},[[_c('v-icon',{attrs:{"small":"","color":"conflict"}},[_vm._v("mdi-alert-circle-outline")]),_c('div',{staticClass:"flex-grow-1"},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.survey.problematicCount))+" photos need attention ")])]],2):_vm._e()]}}])}),_c('div',{staticClass:"survey-edit-step mt-8",class:{
          'survey-edit-step--active': _vm.activeStep === 3,
          'survey-edit-step--complete': _vm.step3Complete,
          'survey-edit-step--disabled': !(_vm.step1Complete && _vm.step2Complete),
        }},[_c('div',{staticClass:"survey-edit-step__inner"},[_c('div',{staticClass:"survey-edit-step__icon"},[(!_vm.step3Complete)?_c('h4',[_vm._v("3")]):_c('v-icon',[_vm._v("mdi-check")])],1),_vm._m(2),_c('div',{staticClass:"survey-edit-step__actions"},[_c('v-btn',{attrs:{"depressed":"","large":"","color":"primary","disabled":_vm.activeStep !== 3},on:{"click":_vm.publishSurvey}},[_vm._v(" Publish ")])],1)])])],1)]):_vm._e(),_c('fauna-media-gallery',{attrs:{"survey":_vm.survey,"galleryStatusIn":_vm.galleryStatusIn,"galleryMode":_vm.galleryMode},on:{"update:galleryStatusIn":function($event){_vm.galleryStatusIn=$event},"update:gallery-status-in":function($event){_vm.galleryStatusIn=$event},"update:galleryMode":function($event){_vm.galleryMode=$event},"update:gallery-mode":function($event){_vm.galleryMode=$event}},model:{value:(_vm.showGalleryDialog),callback:function ($$v) {_vm.showGalleryDialog=$$v},expression:"showGalleryDialog"}}),_c('fauna-media-upload-dialog',{attrs:{"survey":_vm.survey,"uploader":_vm.uploader},model:{value:(_vm.uploadDialog),callback:function ($$v) {_vm.uploadDialog=$$v},expression:"uploadDialog"}}),_c('v-dialog',{attrs:{"value":_vm.deleteProgress < 100,"persistent":"","min-width":"380","max-width":"480"}},[_c('v-card',[_c('v-card-title',[_c('h4',[_vm._v("Deleting images...")])]),_c('v-card-text',[_c('v-progress-linear',{attrs:{"value":_vm.deleteProgress,"rounded":""}})],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-16"},[_c('h2',{staticClass:"mb-2"},[_vm._v("Creating a Survey")]),_c('div',[_vm._v(" A WildTracker survey includes all the images collected by a camera-trap in a single location over a continuous period of "),_c('strong',[_vm._v("one month")]),_vm._v(". If you can't have your camera out for that long, or should it fail for any reason, you can still upload your photos here. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"survey-edit-step__content"},[_c('h4',{staticClass:"survey-edit-step__title"},[_vm._v("When and where")]),_c('div',{staticClass:"survey-edit-step__subtitle"},[_vm._v(" Select a site and the start and end dates for your survey. ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"survey-edit-step__content"},[_c('h4',{staticClass:"survey-edit-step__title"},[_vm._v("Publish your survey")]),_c('div',{staticClass:"survey-edit-step__subtitle"},[_vm._v(" Once you've uploaded all of your photos, you can publish your survey for classification. Make sure you've added all the photos that you want, as you won't be able to make changes once published. ")])])}]

export { render, staticRenderFns }